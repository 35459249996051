<template>
  <div>
    <div>
      <template v-if="!isAdd">
        <b-form>
          <div>
            <h1 class="mr-sm-4 header-tablepage">Member Tier Detail</h1>
          </div>
          <div class="title-tabs mt-3">
            <div class="content-between">
              <div>Member Tier Info</div>
              <InputSelectLanguage ref="inputLang" @changeLang="changeLang" />
            </div>
          </div>
          <div v-if="isLoading">
            <OtherLoading />
          </div>
          <b-tabs v-model="tabIndex" v-else>
            <b-tab title="Detail">
              <b-container class="no-gutters bg-white">
                <div class="py-3">
                  <b-row>
                    <b-col>
                      <InputText
                        textFloat="Name"
                        placeholder="Name"
                        type="text"
                        name="name"
                        isRequired
                        :isValidate="$v.form.name.$error"
                        :v="$v.form.name"
                        v-model="form.name"
                      />
                    </b-col>
                    <b-col>
                      <InputText
                        textFloat="Display Name"
                        placeholder="Display Name"
                        type="text"
                        name="display"
                        isRequired
                        v-model="
                          form.member_level_language[languageIndex].display
                        "
                        :isValidate="
                          $v.form.member_level_language.$each.$iter[
                            languageIndex
                          ]?.display.$error
                        "
                        :v="
                          $v.form.member_level_language.$each.$iter[
                            languageIndex
                          ]?.display
                        "
                      />
                    </b-col>
                    <div class="break-normal"></div>
                    <b-col>
                      <ColorPicker
                        textFloat="Background Color"
                        v-model="form.bg_color"
                        :value="form.bg_color"
                        name="bg-color"
                      />
                    </b-col>
                    <b-col
                      ><ColorPicker
                        textFloat="Font Color"
                        v-model="form.font_color"
                        :value="form.font_color"
                        name="font-color"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <label class="label">Entry Point</label>
                      <b-form-checkbox
                        class="mt-1"
                        v-model="form.is_entry_point"
                        name="checkbox-1"
                        :value="1"
                        :unchecked-value="0"
                      >
                        Entry Point
                      </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <InputText
                        textFloat="Baht Per Point"
                        placeholder="Baht Per Point"
                        type="number"
                        name="baht_per_point"
                        isRequired
                        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                        :v="$v.form.baht_per_point"
                        v-model="form.baht_per_point"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <InputText
                        textFloat="Upgrade Baht"
                        placeholder="Upgrade Baht"
                        type="number"
                        name="upgrade_baht"
                        :v="$v.form.upgrade_baht"
                        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                        v-model="form.upgrade_baht"
                        :isValidate="requiredUpgradeBaht ? true : false"
                        :class="requiredUpgradeBaht ? 'mb-0' : ''"
                      />
                      <div v-if="requiredUpgradeBaht">
                        <span class="text-error">Please Input.</span>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="d-flex align-items-center">
                        <span class="label">Upgrade Tier</span>
                      </div>
                      <InputSelect
                        name="Upgrade Tier"
                        isRequired
                        v-model="form.upgrade_tier_id"
                        v-bind:options="upgradeTier"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option :value="0"
                            >-- Please select value --</b-form-select-option
                          >
                        </template>
                      </InputSelect>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <InputText
                        textFloat="Maintain Baht"
                        placeholder="Maintain Baht"
                        type="number"
                        name="maintain_baht"
                        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
                        v-model="form.maintain_baht"
                      />
                    </b-col>
                    <b-col>
                      <div class="d-flex align-items-center">
                        <span class="label">Downgrade Tier</span>
                      </div>
                      <InputSelect
                        title=""
                        name="Downgrade Tier"
                        isRequired
                        v-model="form.downgrade_tier_id"
                        v-bind:options="upgradeTier"
                        valueField="id"
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option :value="0"
                            >-- Please select value --</b-form-select-option
                          >
                        </template>
                      </InputSelect>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Expire conditions"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-radio-group
                          id="expire-options"
                          v-model="form.expire_condition_id"
                          :options="expire_options"
                          :aria-describedby="ariaDescribedby"
                          :disabled="form.downgrade_tier_id == ''"
                          name="expire-options"
                        ></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <InputText
                        :textFloat="
                          form.expire_condition_id == 3
                            ? 'Expire Period (X Year)'
                            : 'Expire Period (X Days)'
                        "
                        placeholder="0"
                        type="number"
                        name="membership_period"
                        v-model="form.membership_period"
                        :disabled="form.downgrade_tier_id == ''"
                        :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 3);'"
                      />
                    </b-col>
                    <b-col cols="12" class="mb-3">
                      <b-form-checkbox
                        class="mt-1"
                        v-model="form.total_spending_carry_on"
                        name="total-spending"
                        :value="1"
                        :unchecked-value="0"
                      >
                        Total spending in this tier will be includes in next
                        tier when upgrade
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <UploadFile
                        textFloat="Icon Status"
                        placeholder="Please Select a File To Upload."
                        format="image"
                        name="thumbnail"
                        :fileName="form.icon"
                        :accept="acceptType"
                        text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                        isRequired
                        v-on:onFileChange="(val) => onImageChange(val, 'icon')"
                        :v="$v.form.icon"
                        :isValidate="$v.form.icon.$error"
                      />
                      <PreviewBox
                        v-on:delete="onDeleteImage('icon')"
                        :disabled="isDisable"
                        :showPreviewType="0"
                        :showPreview="showPreview['icon']"
                        :isLoadingImage="isLoadingImage['icon']"
                        :ratioType="1"
                      />
                    </b-col>
                    <div class="break-normal"></div>
                    <b-col md="6" class="mt-3">
                      <UploadFile
                        textFloat="Member Card Front (appear in line crm with card layout setting)"
                        placeholder="Please Select a File To Upload."
                        format="image"
                        name="thumbnail"
                        :fileName="form.card_layout_front"
                        :accept="acceptType"
                        text="*Please upload only file .png, .jpg  and less than 10 MB"
                        v-on:onFileChange="
                          (val) => onImageChange(val, 'card_layout_front')
                        "
                      />
                      <PreviewBox
                        v-on:delete="onDeleteImage('card_layout_front')"
                        :disabled="isDisable"
                        :showPreviewType="0"
                        :showPreview="showPreview['card_layout_front']"
                        :isLoadingImage="isLoadingImage['card_layout_front']"
                        :ratioType="16"
                      />
                    </b-col>
                  </b-row>
                </div> </b-container
            ></b-tab>
            <b-tab title="Information">
              <div class="title-tabs">Member Tier Card Detail</div>
              <b-container class="no-gutters bg-white py-2">
                <MemberCardDetail
                  :form="form"
                  @cardDetail="showCardDetail"
                  :languageIndex="languageIndex"
                  v-if="tabIndex == 1"
                />
              </b-container>
            </b-tab>
          </b-tabs>
        </b-form>
      </template>

      <AddPrivilege
        v-else
        ref="privilege"
        :languageIndex="languageIndex"
        :langList="$refs.inputLang.languageList"
      />
      <FooterAction @submit="handleSubmit()" @routePath="cancel()" backAction />
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

import OtherLoading from "@/components/loading/OtherLoading";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
import MemberCardDetail from "../components/MemberCardDetail.vue";
import ColorPicker from "@/components/inputs/InputColorPicker";
import AddPrivilege from "../components/AddPrivilege.vue";
import InputSelectLanguage from "@/components/inputs/InputSelectLanguage";
export default {
  components: {
    InputSelectLanguage,
    OtherLoading,
    UploadFile,
    PreviewBox,
    ColorPicker,
    MemberCardDetail,
    AddPrivilege,
  },
  validations: {
    form: {
      name: { required },
      baht_per_point: { required },
      icon: { required },
      // display: { required },
      member_level_language: {
        $each: {
          display: {
            required: requiredIf(function (item, value) {
              return item.language_id == 1 ? true : false;
            }),
          },
        },
      },
      membership_period: {},
      maintain_baht: {},
    },
  },

  data() {
    return {
      isLoading: false,
      noPleaseSelect: true,
      languageIndex: 0,
      form: {
        name: "",
        expire_condition_id: 1,
        membership_period: 0,
        is_entry_point: 0,
        baht_per_point: 0,
        upgrade_tier_id: 0,
        upgrade_baht: 0,
        downgrade_tier_id: 0,
        membership_period: 0,
        maintain_baht: 0,
        total_spending_carry_on: 0,
        icon: "",
        display: "",
        description: "",
        font_color: "#00000",
        bg_color: "#ffffff",
        member_level_detail: [],
        member_level_language: [
          {
            id: 0,
            language_id: 1,
            member_level_id: this.$route.params.id,
            display: "",
            description: "",
          },
        ],
      },
      upgradeTier: [],
      isDisable: true,
      requiredMemberPeriod: false,
      requiredMaintainBaht: false,
      requiredUpgradeBaht: false,
      id: this.$route.params.id,
      acceptType: "image/jpeg, image/png",
      showPreview: { icon: "", card_layout_back: "", card_layout_front: "" },
      isLoadingImage: {
        icon: false,
        card_layout_back: false,
        card_layout_front: false,
      },
      expire_options: [
        { text: "Days", value: 1 },
        { text: "End of Year", value: 2 },
        { text: "End Next Nth Year", value: 3 },
      ],
      isAdd: false,
      tabIndex: 0,
    };
  },
  async created() {
    this.isLoading = true;
    await this.getDropdown();
    if (this.id !== "0") {
      await this.getMemberDetail();
    }
  },
  methods: {
    async getDropdown() {
      await this.$store.dispatch("getDropdown");
      const data = this.$store.state.memberTier.dropdown;
      if (data.result == 1) {
        this.upgradeTier = data.detail;
        this.isLoading = false;
      }
    },
    async getMemberDetail(id = 1) {
      this.$bus.$emit("showLoading");
      await this.$store.dispatch("getDetailMember", { id: this.id, lang: id });
      const data = this.$store.state.memberTier.detailMember;
      this.$bus.$emit("hideLoading");
      if (data.result === 1) {
        this.form = data.detail;
        if (data.detail.member_level_language.length > 0) {
          if (data.detail.member_level_language[0].display == "") {
            data.detail.member_level_language[0].display = this.form.display;
          }
        }
        this.showPreview.icon = this.form.icon;
        this.showPreview.card_layout_front = this.form.card_layout_front;
        this.showPreview.card_layout_back = this.form.card_layout_back;
        this.isLoading = false;
      }
    },
    async saveForm() {
      try {
        this.$v.form.$touch();

        if (this.$v.form.$error) {
          this.$refs.inputLang.setValue(1);
          // this.changeLang(1);
          return;
        }
        this.$bus.$emit("showLoading");
        this.form.upgrade_baht = parseInt(this.form.upgrade_baht);
        this.form.baht_per_point = parseInt(this.form.baht_per_point);
        this.form.membership_period =
          parseInt(this.form.membership_period) || 0;
        this.form.maintain_baht = parseInt(this.form.maintain_baht) || 0;
        if (this.id === "0") {
          await this.$store.dispatch("createdMemberTier", this.form);
          const data = this.$store.state.memberTier.createdMemberTier;
          if (data.result === 1) {
            this.successAlert().then(() => {
              this.$router.push("/membertier");
            });
          } else {
            this.isLoading = false;
            this.errorAlert(data.message);
          }
        } else {
          let reqBody = {
            form: this.form,
            id: this.id,
          };
          await this.$store.dispatch("updateMemberTier", reqBody);
          const data = this.$store.state.memberTier.updateMember;
          if (data.result === 1) {
            this.successAlert().then(() => {
              this.$router.push("/membertier");
            });
          } else {
            this.isLoading = false;
            this.errorAlert(data.message);
          }
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.$bus.$emit("hideLoading");
      }
    },
    onImageChange(img, key) {
      this.isLoadingImage[key] = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage[key] = false;
        this.isDisable = false;
        this.form[key] = this.images;
        this.showPreview[key] = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage(key) {
      this.form[key] = "";
      this.showPreview[key] = "";
    },
    showCardDetail(value) {
      this.isAdd = true;
      if (value) this.$nextTick(() => (this.$refs.privilege.item = value));
    },
    handleSubmit() {
      if (this.isAdd) this.addDetailList();
      else this.saveForm();
    },
    addDetailList() {
      this.$refs.privilege.$v.$touch();
      if (this.$refs.privilege.$v.$error) return;
      let items = this.$refs.privilege.item;
      if (items.id != 0) {
        let index = this.form.member_level_detail.findIndex(
          (el) => el.id == items.id
        );
        this.form.member_level_detail.splice(index, 1, items);
      } else if (items.tempid) {
        let index = this.form.member_level_detail.findIndex(
          (el) => el.tempid == items.tempid
        );
        this.form.member_level_detail.splice(index, 1, items);
      } else {
        this.form.member_level_detail.push({
          ...items,
          tempid: this.form.member_level_detail.length + 1,
        });
      }
      this.cancel();
    },
    cancel() {
      if (this.isAdd) {
        this.isAdd = false;
        this.tabIndex = 1;
      } else {
        this.$router.push("/membertier");
      }
    },
    async changeLang(val) {
      this.isLoading = true;

      if (
        this.form.member_level_language.length !=
        this.$refs.inputLang.languageList.length
      ) {
        // this.$nextTick(() => {
        for (const { id } of this.$refs.inputLang.languageList) {
          let index = this.form.member_level_language.find(
            (el) => el.language_id == id
          );
          if (!index)
            this.form.member_level_language.push({
              id: 0,
              language_id: id,
              member_level_id: 0,
              display: "",
              description: "",
            });
        }
        // });
      }
      let index = this.form.member_level_language.findIndex(
        (el) => el.language_id == val
      );
      await this.$nextTick();
      this.isLoading = false;
      this.languageIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-select {
  color: var(--font-main-color);
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
  height: 31px;
}

.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.delete-icon {
  top: 0;
  right: 0;
}
</style>
