<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage">Member Tier Card Detail</h1>

    <div class="title-tabs mt-3">Member Tier Card Setting</div>
    <b-container class="bg-white py-3">
      <b-row class="row-gap-lg">
        <b-col cols="12" md="6">
          <UploadFile
            textFloat="Image"
            placeholder="Please Select a File To Upload."
            format="image"
            name="thumbnail"
            :fileName="item.image"
            accept="image/jpeg, image/png"
            text="*Please upload only file .png, .jpg size 1:1  and less than 5 MB"
            isRequired
            v-on:onFileChange="(val) => onImageChange(val, 'image')"
            :v="$v.item.image"
            :isValidate="$v.item.image.$error"
            :maxSize="50000000"
          />
          <PreviewBox
            v-on:delete="onDeleteImage('image')"
            :disabled="isDisable"
            :showPreviewType="0"
            :showPreview="item.image"
            :isLoadingImage="isLoading"
            :ratioType="1"
          />
        </b-col>
        <b-col cols="12" md="6">
          <InputText
            textFloat=""
            placeholder="Title"
            type="text"
            name="Title"
            isRequired
            v-model="item.member_level_detail_language[languageIndex].title"
            customLabel
            :maxLength="60"
            :isValidate="
              $v.item.member_level_detail_language.$each.$iter[languageIndex]
                .title.$error
            "
            :v="
              $v.item.member_level_detail_language.$each.$iter[languageIndex]
                .title
            "
          >
            <template #textFloat>
              <div class="content-between">
                <div>Title <span class="text-danger">*</span></div>
                <div class="count-characters">
                  {{
                    item.member_level_detail_language[languageIndex].title
                      .length | numeral("0,0")
                  }}/{{ 60 | numeral("0,0") }}
                </div>
              </div>
            </template>
          </InputText>
        </b-col>
        <b-col cols="12">
          <TextEditorsTiny
            textFloat="Description"
            :rows="4"
            :value="
              item.member_level_detail_language[languageIndex].description
            "
            isRequired
            :name="'description_' + 1"
            placeholder="Type something..."
            @onDataChange="
              (val) =>
                (item.member_level_detail_language[languageIndex].description =
                  val)
            "
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
import { maxLength } from "vuelidate/lib/validators";
export default {
  components: {
    UploadFile,
    PreviewBox,
  },
  props: {
    data: {
      required: false,
    },
    languageIndex: {
      required: false,
    },
    langList: {
      required: false,
    },
  },
  validations: {
    item: {
      image: { required },
      member_level_detail_language: {
        $each: {
          title: {
            required: requiredIf(function (item, value) {
              return item.language_id == 1 ? true : false;
            }),
          },
          description: {
            required: requiredIf(function (item, value) {
              return item.language_id == 1 ? true : false;
            }),
          },
        },
      },
    },
  },
  data() {
    return {
      item: {
        description: "",
        image: "",
        title: "",
        id: 0,
        member_level_id: this.$route.params.id,
        member_level_detail_language: [
          {
            id: 0,
            language_id: this.$store.state.currentLanguage,
            member_level_detail_id: 0,
            title: "",
            description: "",
          },
        ],
      },
      isLoading: false,
      isDisable: false,
    };
  },
  created() {
    if (this.item.member_level_detail_language.length != this.langList.length) {
      for (const { id } of this.langList) {
        let index = this.item.member_level_detail_language.find(
          (el) => el.language_id == id
        );
        if (!index)
          this.item.member_level_detail_language.push({
            id: 0,
            language_id: id,
            member_level_detail_id: 0,
            title: "",
            description: "",
          });
      }
    }
  },
  methods: {
    onImageChange(img, key) {
      this.isLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.isDisable = false;
        this.item[key] = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage(key) {
      this.item[key] = "";
    },
  },
};
</script>

<style>
.count-characters {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 0px;
}
</style>
