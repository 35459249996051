<template>
  <div>
    <template v-if="!isAdd">
      <div class="d-flex align-items-center">
        <b-button
          variant="outline-primary"
          @click="addList()"
          :disabled="items.length >= 10"
        >
          <font-awesome-icon icon="plus-square" class="mr-2" /> Add New
        </b-button>
        <div class="ml-2">({{ items.length }}/10 Card)</div>
      </div>
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
        class="mt-2"
      >
        <template v-slot:cell(image)="data">
          <b-img class="image-size" :src="data.item.image" />
        </template>
        <template v-slot:cell(title)="data">
          <!-- <b-img class="image-size" :src="data.item.image" /> -->
          {{
            data.item.member_level_detail_language[languageIndex].title ||
            data.item.title
          }}
        </template>
        <template v-slot:cell(description)="data">
          <div
            v-html="
              data.item.member_level_detail_language[languageIndex]
                .description || data.item.description
            "
            class="description"
          ></div>
        </template>
        <template v-slot:cell(action)="data">
          <b-button variant="custom-link">
            <font-awesome-icon
              icon="pencil-alt"
              class="text-warning mr-1"
              @click="addList(data.item)"
            >
            </font-awesome-icon>
          </b-button>
          <b-button variant="custom-link">
            <font-awesome-icon
              icon="trash-alt"
              @click="removeList(data.index)"
            ></font-awesome-icon>
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
    },
    languageIndex: {
      required: true,
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { label: "Image", key: "image", class: "w-1" },
        { label: "Title", key: "title", class: "w-3" },
        { label: "Description", key: "description" },
        { label: "Action", key: "action", class: "w-1" },
      ],
      isBusy: false,
      isAdd: false,
    };
  },
  created() {
    this.items = this.form.member_level_detail;
  },
  methods: {
    addList(value) {
      if (!value) return this.$emit("cardDetail");
      this.$emit("cardDetail", value);
    },
    removeList(index) {
      this.items.splice(index, 1);
      this.form.member_level_detail.splice(index, 1);
      // this.$emit("cardDetail", value);
    },
  },
};
</script>

<style>
.image-size {
  width: 50px;
  height: 50px;
}
</style>
